<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="物品类型:">
          <el-select 
						v-model="searchForm.type" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="selectref"
						placeholder="请选择" 
						clearable>
            <el-option
              v-for="item in typeSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物品名称:">
          <el-input 
						v-model.trim="searchForm.name" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="所属教学点名称:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
       <el-table-column
        label="物品名称"
        prop="name"
        align="center"
      >
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.name
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="物品类型"
        prop="type"
        align="center"
      ></el-table-column>
      <el-table-column
        label="物品单位"
        prop="unit"
        align="center"
      ></el-table-column>
      <el-table-column
        label="初始数量"
        prop="initialnum"
        align="center"
      ></el-table-column>
      <el-table-column
        label="当前数量"
        prop="currentnum"
        align="center"
      ></el-table-column>
      <el-table-column label="录入时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        label="录入人"
        prop="insertusername"
        align="center"
      ></el-table-column>
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        width="140"
        align="center"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        :rules="rules"
        status-icon
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="type" label="物品类型：">
          <el-select
            v-model="dynamicValidateForm.type"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in typeSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name" label="物品名称：">
          <el-input v-model.trim="dynamicValidateForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="物品单位：">
          <el-input v-model.trim="dynamicValidateForm.unit" clearable></el-input>
        </el-form-item>
        <el-form-item prop="initialnum" label="初始数量：">
          <el-input v-model.trim="dynamicValidateForm.initialnum" clearable></el-input>
        </el-form-item>
        <el-form-item prop="currentnum" label="当前数量：">
          <el-input v-model.trim="dynamicValidateForm.currentnum" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
			:close-on-click-modal='false'
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
        <tr>
          <td>物品类型：{{ dynamicValidateForm.type }}</td>
          <td>物品名称：{{ dynamicValidateForm.name }}</td>
          <td>物品单位：{{ dynamicValidateForm.unit }}</td>
        </tr>
        <tr>
          <td>初始数量：{{ dynamicValidateForm.initialnum }}</td>
          <td>当前数量：{{ dynamicValidateForm.currentnum }}</td>
          <td>录入时间：{{ dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
        </tr>
        <tr>
          <td>录入人：{{ dynamicValidateForm.insertusername }}</td>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
        </tr>
				<tr>
					<td>流水号： {{dynamicValidateForm.id}}</td>
					<td></td>
					<td></td>
				</tr>
      </table>
    </el-dialog>

    <!-- 入库 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="35%"
      title="入库："
      :visible.sync="showHouseModal"
			:close-on-click-modal='false'
			v-if="showHouseModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        :rules="rules"
        status-icon
      >
        <el-form-item prop="type" label="物品类型：">
          <el-input v-model="dynamicValidateForm.type" disabled></el-input>
        </el-form-item>
        <el-form-item prop="name" label="物品名称：">
          <el-input v-model="dynamicValidateForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="物品单位：">
          <el-input v-model="dynamicValidateForm.unit" disabled></el-input>
        </el-form-item>
        <el-form-item prop="currentnum" label="当前数量：">
          <el-input
            v-model="dynamicValidateForm.currentnum"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="warehousingnum"
          label="入库数量："
          :rules="[{ validator: checkfield, required: true }]"
        >
          <el-input v-model.trim="dynamicValidateForm.warehousingnum" clearable></el-input>
        </el-form-item>

        <el-form-item prop="inserttime" label="入库时间：">
          <el-date-picker
            v-model="dynamicValidateForm.inserttime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"

          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPutHouse('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 领取 -->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
      title="领取："
      :visible.sync="showReceiveModal"
			:close-on-click-modal='false'
			v-if="showReceiveModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        :rules="rules"
        status-icon
      >
        <el-form-item prop="type" label="物品类型：">
          <el-input v-model="dynamicValidateForm.type" disabled></el-input>
        </el-form-item>
        <el-form-item prop="name" label="物品名称：">
          <el-input v-model="dynamicValidateForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="物品单位：">
          <el-input v-model="dynamicValidateForm.unit" disabled></el-input>
        </el-form-item>
        <el-form-item prop="currentnum" label="当前数量：">
          <el-input
            v-model="dynamicValidateForm.currentnum"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="collectnum"
          label="领取数量："
          :rules="[{ validator: checkfield, required: true }]"
        >
          <el-input v-model.trim="dynamicValidateForm.collectnum" clearable></el-input>
        </el-form-item>

        <el-form-item prop="collecttime" label="领取时间：">
          <el-date-picker
            v-model="dynamicValidateForm.collecttime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"

          >
          </el-date-picker>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPutHouse('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 盘点 -->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
      title="盘点："
      :visible.sync="showCheckModal"
			:close-on-click-modal='false'
			v-if="showCheckModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        :rules="rules"
        status-icon
      >
        <el-form-item prop="type" label="物品类型：">
          <el-input v-model="dynamicValidateForm.type" disabled></el-input>
        </el-form-item>
        <el-form-item prop="name" label="物品名称：">
          <el-input v-model="dynamicValidateForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="物品单位：">
          <el-input v-model="dynamicValidateForm.unit" disabled></el-input>
        </el-form-item>
        <el-form-item prop="currentnum" label="当前数量：">
          <el-input
            v-model="dynamicValidateForm.currentnum"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="inventorynum"
          label="盘点数量："
          :rules="[{ validator: checkfield, required: true }]"
        >
          <el-input v-model.trim="dynamicValidateForm.inventorynum" clearable></el-input>
        </el-form-item>
        <el-form-item prop="inserttime" label="盘点时间：">
          <el-date-picker
            v-model="dynamicValidateForm.inserttime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          prop="remark"
          label="备注："
          :rules="[{ required: true, message: '必填字段', trigger: 'blur' }]"
        >
          <el-input v-model.trim="dynamicValidateForm.remark" clearable></el-input>
        </el-form-item>
        
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPutHouse('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <Export
      :exportTitle="'库存管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
    ></Import>
  </div>
</template>
<script>
import { part } from "@/utils/mixins";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import { mapActions } from "vuex";
import {
  stockmanage_list,
  stockmanage_input,
  stockmanage_save,
  stockmanage_delete,
  stockmanage_export,
  stockmanage_exportexcel,
  stockmanage_exportsave,
  warehousing_save,
  collect_save,
  inventory_save,
  stockmanage_importsave,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "stockmanage",
  components: {
    Export,
    Import,
  },
  props: {
    exportUrl: {
      default: () => stockmanage_export,
    },
    exportexcelUrl: {
      default: () => stockmanage_exportexcel,
    },
    exportsaveUrl: {
      default: () => stockmanage_exportsave,
    },
    importSaveUrl: {
      default: () => stockmanage_importsave,
    },
  },
  data() {
    //表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框
      importDialog: false, //导入框
      checkfield: (rule, value, callback) => {
        if (!(value + "")) {
          return callback(new Error("必填字段"));
        } else {
          if (isNaN(Number(value))) {
            callback(new Error("请输入数字值"));
          } else {
            callback();
          }
        }
      },
      itemId: null, //每一项id
      searchForm: {
        id: "",
        type: "",
        name: "",
        schoolorgname: "",
      }, //搜索表单
      typeSelect: [
        {
          value: "教材",
          label: "教材",
        },
        {
          value: "耐用品",
          label: "耐用品",
        },
        {
          value: "易耗品",
          label: "易耗品",
        },
      ], //物品类型
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      showHouseModal: false, //显示入库框
      showReceiveModal: false, //显示领取框
      showCheckModal: false, //显示盘点框
      dynamicValidateForm: {}, //表单
      wayTypeUrl: "", //入库，领取，盘点类型
      rules: {
        companyorgid: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        schoolorgid: [{ required: true, message: "必填字段", trigger: "blur" }],
        type: [{ required: true, message: "必填字段", trigger: "blur" }],
        name: [{ required: true, message: "必填字段", trigger: "blur" }],
        unit: [{ required: true, message: "必填字段", trigger: "blur" }],
        initialnum: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        currentnum: [
          { trigger: "blur", validator: checkfield, required: true },
        ],

        inserttime: [{ required: true, message: "必填字段", trigger: "blur" }],
        collecttime: [{ required: true, message: "必填字段", trigger: "blur" }],
      }, //表单规则
      multipleSelection: [], //选中的表格项
      dialogTitle: "", //对话框标题
      showDetailModal: false,

    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  mounted() {},

  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				type: null,
				name: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: stockmanage_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
// 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //添加click
    btnAdd() {
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.dialogTitle = "添加";
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //入库 click
    btnPutHouse() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dynamicValidateForm =  JSON.parse(JSON.stringify(this.multipleSelection[0]))
        this.dynamicValidateForm.inserttime = '' 
        this.dynamicValidateForm.stockmanageid = this.dynamicValidateForm.id
        delete this.dynamicValidateForm.id 
        this.wayTypeUrl = warehousing_save
        this.showHouseModal = true;
      });
    },
    //领取 click
    btnReceive() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dynamicValidateForm =  JSON.parse(JSON.stringify(this.multipleSelection[0]))
        this.$set(this.dynamicValidateForm,'collecttime','')
      
        this.dynamicValidateForm.stockmanageid = this.dynamicValidateForm.id
          delete this.dynamicValidateForm.id 
        this.wayTypeUrl = collect_save
        this.showReceiveModal = true;
      });
    },
    //盘点 click
    btnInventory() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dynamicValidateForm =  JSON.parse(JSON.stringify(this.multipleSelection[0]))
        this.dynamicValidateForm.inserttime = '' 
        this.dynamicValidateForm.stockmanageid = this.dynamicValidateForm.id
        delete this.dynamicValidateForm.id 

        this.wayTypeUrl = inventory_save
        this.showCheckModal = true;
      });
    },

    //导入 click
    btnImport() {
      this.importDialog = true;
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: stockmanage_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
            }
          ).then((res) => {
            if (res.data.code === "200") {
              this.showAddModal = false;
            }
          });
        }
      });
    },
    //提交入库，领取，盘点 click
    submitPutHouse(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitOtherForm();
        }
      });
    },
    //提交入库，领取，盘点 api
    submitOtherForm() {
      myRequest(
        {
          url: this.wayTypeUrl,
          data: this.dynamicValidateForm,
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.showHouseModal = this.showReceiveModal = this.showCheckModal = false;
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: stockmanage_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vstockmanage;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          this.showAddModal = true;
        }
      });
    },
    //删除表格项 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: stockmanage_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
  },
};
</script>

<style  lang="scss">
</style>